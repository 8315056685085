import { BackpackIcon, DashboardIcon, LayersIcon, ListBulletIcon, MagnifyingGlassIcon } from "@radix-ui/react-icons";
import { useActionData } from "@remix-run/react";
import { atom, useAtom } from "jotai";
import { useEffect, useRef, useState } from "react";
import { useInstantSearch, useSearchBox } from "react-instantsearch";
import { Badge, Box, Flex, Heading, Section, Text, TextArea, TextInput } from "sparta";
import { Button, Form, ModalV2 } from "sparta";
// import savesearchmodal from "~/assets/savesearchmodal.png";
import { TagGroup } from "~/components/search/PostHits/PostHit/TagGroup";
import { SavedSearchIcon } from "~/icons";
import { RefinementMultiSelect } from "./RefinementMultiSelect";

/**
 * Given a uiState from instantsearch, build a name for the search in the form
 * [DATE] - [TAGS].[PLATFORM].[AGENCY]
 * @param posts The uiState from instantsearch
 * @returns The name of the search
 */
function buildName(posts: any) {
  const date = new Date().toLocaleDateString();
  const query = posts?.query;
  const tags = posts?.refinementList?.tags ? posts?.refinementList?.tags?.join(".") : "";
  const platforms = posts?.refinementList?.platforms ? posts?.refinementList?.platforms?.join(".") : "";
  const agency = posts?.refinementList?.agency ? posts?.refinementList?.agency?.join(".") : "";
  const postType = posts?.refinementList?.postType ? posts?.refinementList?.postType?.join(".") : "";

  return `${date} - ${[tags, platforms, agency, postType, query].filter((v) => !!v).join(".")}`.toUpperCase();
}

export function SaveSearchEditingModal({
  id,
  open,
  setOpen,
}: { id?: string; open: boolean; setOpen: (open: boolean) => void }) {
  const actionData = useActionData<any>();
  const dataError: string = actionData?.formError;
  const { uiState } = useInstantSearch();
  const { posts } = uiState;
  const [showAllModal, setShowAllModal] = useState<boolean>(false);
  const { query, refine } = useSearchBox();
  const [inputValue, setInputValue] = useState(query);
  const searchRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (actionData?.formError) {
      setOpen(true); // Open the modal if there's a form error
    } else if (actionData && !actionData.formError) {
      setOpen(false); // Close the modal if the form was successful
    }
  }, [actionData]);

  const tags = posts?.refinementList?.tags ?? [];
  const platforms = posts?.refinementList?.platforms ?? [];

  function setQuery(newQuery: string) {
    setInputValue(newQuery);
    refine(newQuery);
  }

  return (
    <ModalV2.Root open={open} onOpenChange={setOpen}>
      <ModalV2.Content>
        <Form
          method="post"
          id="add_search_name"
          actionData={actionData?.fields?.defaultOpen === "add_search_name" ? actionData : undefined}
        >
          <ModalV2.Title>
            <Heading as="h2" size={"5"}>
              Get Notified
            </Heading>
          </ModalV2.Title>
          <ModalV2.Description>
            Stay in the loop with a Saved Search. Set your filters and get personalized email notifications whenever new
            content matches that your criteria is published. Enjoy tailored market intelligence.
          </ModalV2.Description>
          <Flex direction="column" align="center" justify="center" gap="16px">
            <SavedSearchIcon height="300" />
            <input type="hidden" name="query" value={posts?.query} />
            <input type="hidden" name="tags" value={posts?.refinementList?.tags} />
            <input type="hidden" name="platforms" value={posts?.refinementList?.platforms} />
            <input type="hidden" name="agencies" value={posts?.refinementList?.agency} />
            <input type="hidden" name="postTypes" value={posts?.refinementList?.postType} />
            <Flex direction="column" align="start" style={{ alignSelf: "stretch" }}>
              <TextInput
                label="Name your Search"
                name="add_search_name"
                formId={`save-search-name-${posts}`}
                placeholder="Enter a name"
                defaultValue={buildName(posts)}
              />
            </Flex>
            <Flex
              direction="column"
              p="12px"
              gap="12px"
              align="start"
              style={{ alignSelf: "stretch", borderRadius: "3px", border: "0.5px solid #C1C7CB" }}
            >
              <Heading as="h3" size="3">
                Preview Saved Search
              </Heading>
              <Flex align="center" gap="10px" style={{ alignSelf: "stretch" }}>
                <Text
                  style={{
                    color: "#6C8189",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Keyword:{" "}
                </Text>
                <Text
                  style={{
                    color: "#313B43",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  {posts?.query}
                </Text>
                <Box width={{ initial: "100%", md: "600px" }}>
                  <TextInput
                    ref={searchRef}
                    value={inputValue}
                    onChange={(e) => {
                      setQuery(e.currentTarget.value);
                    }}
                    style={{ width: "100%" }}
                    placeholder="Search"
                    leftSection={<MagnifyingGlassIcon />}
                  />
                </Box>
              </Flex>
              <Flex align="center" gap="10px" style={{ alignSelf: "stretch" }} wrap={"wrap"}>
                <Text
                  style={{
                    color: "#6C8189",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Tags:{" "}
                </Text>
                {(tags ?? []).map((tag) => (
                  <Badge color="green" variant="solid" key={tag}>
                    {tag}
                  </Badge>
                ))}
                <RefinementMultiSelect
                  attribute="tags"
                  searchable
                  searchablePlaceholder="Tags"
                  limit={200}
                  leftIcon={<ListBulletIcon />}
                />
              </Flex>
              <Flex align="center" gap="10px" style={{ alignSelf: "stretch" }} wrap={"wrap"}>
                <Text
                  style={{
                    color: "#6C8189",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Platforms:{" "}
                </Text>
                {platforms.map((platform) => (
                  <Badge color="blue" key={platform}>
                    {platform}
                  </Badge>
                ))}
                <RefinementMultiSelect
                  attribute="platforms"
                  searchable
                  searchablePlaceholder="Platforms"
                  leftIcon={<LayersIcon />}
                />
              </Flex>
              <Flex align="center" gap="10px" style={{ alignSelf: "stretch" }} wrap={"wrap"}>
                <Text
                  style={{
                    color: "#6C8189",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Categories:{" "}
                </Text>
                <Text
                  style={{
                    color: "#313B43",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  {posts?.refinementList?.postType?.join("- ")}
                </Text>
                <RefinementMultiSelect
                  attribute="postType"
                  searchable
                  searchablePlaceholder="Categories"
                  leftIcon={<DashboardIcon />}
                />
              </Flex>
              <Flex align="center" gap="10px" style={{ alignSelf: "stretch" }} wrap={"wrap"}>
                <Text
                  style={{
                    color: "#6C8189",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  Agencies:{" "}
                </Text>
                <Text
                  style={{
                    color: "#313B43",
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: "24px",
                  }}
                >
                  {posts?.refinementList?.agency?.join("- ")}
                </Text>
                <RefinementMultiSelect
                  attribute="agency"
                  searchable
                  sortBy={["name:asc"]}
                  searchablePlaceholder="Agencies"
                  limit={200}
                  leftIcon={<BackpackIcon />}
                />
              </Flex>
            </Flex>
            <Flex direction="column" style={{ alignSelf: "stretch" }}>
              <Flex align="center" justify={"end"} gap="2">
                <Button style={{ backgroundColor: "#10333F", width: "100%" }}>Create</Button>
              </Flex>
            </Flex>
          </Flex>
        </Form>
      </ModalV2.Content>
    </ModalV2.Root>
  );
}

const createSearchModal = atom<boolean>(false);
const searchIsActive = atom<boolean>(false);

type IAddModal = {
  open: boolean;
  setOpen: (open: boolean) => void;
  toggle: () => void;
  isActive: boolean;
  setIsActive: (open: boolean) => void;
  cancel: () => void;
};

// This function returns an object with the current state of the delete search modal
export const useAddSearchModal = (): IAddModal => {
  // Get the current state of the modal from the atom
  const [open, setOpen] = useAtom(createSearchModal);

  const [isActive, setIsActive] = useAtom(searchIsActive);

  const handleCancel = () => {
    setOpen(false);
    setIsActive(false);
  };

  // Return an object with the current state of the modal and functions to update it
  return {
    open,
    setOpen,
    toggle: () => setOpen(!open),
    isActive,
    setIsActive,
    cancel: handleCancel,
  };
};
